var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-popover',{attrs:{"target":("compliance-" + _vm.targetId),"variant":"primary","triggers":"hover","placement":"left","custom-class":"rounded-circle mw-100 w-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mw-auto w-auto border-bottom p-75",staticStyle:{"gap":"1rem"}},[_c('h5',{staticClass:"font-weight-medium mb-0 w-fit"},[_vm._v(_vm._s(_vm.studentName)+"'s Compliance")]),_c('b-badge',{staticClass:"text-capitalize",attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
        score: _vm.sectionsData ? _vm.complianceData.scores.overall : _vm.complianceData.scores.overall,
      })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.sectionsData ? _vm.complianceData.scores.overall : _vm.complianceData.scores.overall, precision: 2, percent: true, status: _vm.sectionsData ? _vm.complianceData.status : _vm.complianceData.status, }))+" Overall ")])],1),(!_vm.sectionsData)?_c('div',{staticClass:"d-flex flex-column p-75 w-100",staticStyle:{"gap":"0.625rem"}},[(_vm.selectModule('attendance'))?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.625rem"}},[_c('font-awesome-icon',{staticClass:"text-info",staticStyle:{"width":"1rem","height":"1rem"},attrs:{"icon":"fa-solid fa-user"}}),_c('p',{staticClass:"d-flex mb-0"},[_vm._v("Attendance")])],1),_c('b-badge',{staticClass:"d-flex text-capitalize",attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
          score: _vm.selectModule('attendance').score,
        })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.selectModule('attendance').score, precision: 2, percent: true, }))+" ")])],1):_vm._e(),(_vm.selectModule('simulations'))?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.625rem"}},[_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":"fa-solid fa-atom-simple"}}),_c('p',{staticClass:"d-flex mb-0"},[_vm._v("Simulations")])],1),_c('b-badge',{staticClass:"d-flex text-capitalize",attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
          score: _vm.selectModule('simulations').score,
        })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.selectModule('simulations').score, precision: 2, percent: true, }))+" ")])],1):_vm._e(),(_vm.selectModule('examedge'))?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.625rem"}},[_c('font-awesome-icon',{staticClass:"text-warning",staticStyle:{"width":"1rem","height":"1rem"},attrs:{"icon":"fa-solid fa-file-lines"}}),_c('p',{staticClass:"d-flex mb-0"},[_vm._v("ExamEdge")])],1),_c('b-badge',{staticClass:"d-flex text-capitalize",attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
          score: _vm.selectModule('examedge').score,
        })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.selectModule('examedge').score, precision: 2, percent: true, }))+" ")])],1):_vm._e(),(_vm.selectModule('labs'))?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.625rem"}},[_c('font-awesome-icon',{staticStyle:{"width":"1rem","height":"1rem","color":"#7367f0"},attrs:{"icon":"fa-solid fa-flask-gear"}}),_c('p',{staticClass:"d-flex mb-0"},[_vm._v("Labs")])],1),_c('b-badge',{staticClass:"d-flex text-capitalize",attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
          score: _vm.selectModule('labs').score,
        })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.selectModule('labs').score, precision: 2, percent: true }))+" ")])],1):_vm._e(),(_vm.selectModule('labs_external'))?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.625rem"}},[_c('font-awesome-icon',{staticStyle:{"width":"1rem","height":"1rem","color":"#7367f0"},attrs:{"icon":"fa-solid fa-flask-gear"}}),_c('p',{staticClass:"d-flex mb-0"},[_vm._v("Labs (external)")])],1),_c('b-badge',{staticClass:"d-flex text-capitalize",attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
          score: _vm.selectModule('labs_external').score,
        })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.selectModule('labs_external').score, precision: 2, percent: true }))+" ")])],1):_vm._e(),(_vm.selectModule('other'))?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.625rem"}},[_c('font-awesome-icon',{staticStyle:{"width":"1rem","height":"1rem","color":"#7367f0"},attrs:{"icon":"fa-solid fa-flask-gear"}}),_c('p',{staticClass:"d-flex mb-0"},[_vm._v("Other")])],1),_c('b-badge',{staticClass:"d-flex text-capitalize",attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
          score: _vm.selectModule('other').score,
        })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.selectModule('other').score, precision: 2, percent: true }))+" ")])],1):_vm._e()]):(_vm.complianceData.sections)?_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.sectionsData),function(section){return (_vm.complianceData.sections[section.id])?_c('div',{key:section.id,staticClass:"d-flex flex-column p-75",staticStyle:{"gap":"0.3rem"}},[_c('p',{staticClass:"d-flex mb-0"},[_vm._v(_vm._s(_vm.stripAfterPipe(section.name)))]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.4rem"}},[_c('b-badge',{staticClass:"text-capitalize",attrs:{"variant":("light-" + (_vm.resolveCourseSectionStatusVariant(section.status)))}},[_vm._v(" "+_vm._s(section.status)+" ")]),_c('b-badge',{staticClass:"text-capitalize",attrs:{"variant":("light-" + (_vm.resolveProfileStatusVariant(section.status)))}},[_vm._v(" "+_vm._s(_vm.title(_vm.complianceData.sections[section.id].status))+" ")]),_c('b-badge',{staticClass:"d-flex text-capitalize",staticStyle:{"width":"fit-content"},attrs:{"variant":("" + (_vm.resolveComplianceScoreVariant({
            score: _vm.complianceData.sections[section.id].scores.overall,
            status: _vm.complianceData.sections[section.id].status,
          })))}},[_vm._v(" "+_vm._s(_vm.gradeScore({ score: _vm.complianceData.sections[section.id].scores.overall, status: _vm.complianceData.sections[section.id].status, precision: 2, percent: true, }))+" ")])],1)]):_vm._e()}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }